import api from './api';

class DevicesService {
  get(id) {
    return api.get(`/devices/${id}`).then((res) => res.data);
  }

  find(params) {
    return api.get('/devices', { params }).then((res) => res.data);
  }

  create(data) {
    return api.post(`/devices`, data).then((res) => res.data);
  }

  update(id, data) {
    return api.patch(`/devices/${id}`, data).then((res) => res.data);
  }

  delete(id) {
    return api.delete(`/devices/${id}`).then((res) => res.data);
  }

  sync(id) {
    return api.post(`/devices/${id}/sync`).then((res) => res.data);
  }

  getStreamLink(id) {
    return api.get(`/devices/${id}/stream-link`).then((res) => res.data);
  }

  getAllUsers(id) {
    return api.get(`/devices/${id}/users`).then((res) => res.data);
  }

  deleteUserInDevice(idDevice, idUser) {
    return api.delete(`/devices/${idDevice}/users/${idUser}`).then((res) => res.data);
  }

  getUserFaceInDevice(idDevice, idUser) {
    return api.get(`/devices/${idDevice}/users/${idUser}/face`).then((res) => res.data);
  }

  softwareVersion(data) {
    return api.post(`/devices/software-version`, data).then((res) => res.data);
  }
}

export default new DevicesService();

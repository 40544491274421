<script>
import DevicesService from '@/service/devices.service';

import { DeviceAccessType } from '../../../utils/enums/DeviceAccessType';

export default {
  name: 'list-devices',

  components: {
    ModalRegisterDevice: () => import('./ModalRegisterDevice.vue'),
    ModalListUsersDevice: () => import('./ModalListUsersDevice.vue'),
  },

  data() {
    return {
      loading: false,
      deviceAccessType: DeviceAccessType,
      options: {
        sortBy: ['name'],
        sortDesc: [false],
      },

      totalItems: 0,
      items: [],

      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Nome', value: 'name' },
        { text: 'Tipo de Acesso', value: 'accessType' },
        { text: 'Modelo', value: 'model' },
        { text: 'Host', value: 'host' },
        { text: 'Ações', align: 'center', value: 'actions' },
      ],
    };
  },

  watch: {
    options: {
      handler() {
        this.fetch();
      },

      deep: true,
    },
  },

  methods: {
    async fetch() {
      this.loading = true;

      try {
        const filter = {
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };

        if (this.options.sortBy.length > 0) {
          filter.sortBy = `${this.options.sortBy[0]}:${this.options.sortDesc[0] ? 'DESC' : 'ASC'}`;
        }

        const { data, meta } = await DevicesService.find(filter);
        this.items = data;
        this.page = meta?.currentPage;
        this.totalItems = meta?.totalItems;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    create() {
      this.$refs.modalRegister.$emit('create');
    },

    edit(device) {
      this.$refs.modalRegister.$emit('edit', device.id);
    },

    remove(device) {
      this.$swal({
        text: `Deseja remover '${device.name}'?`,
        icon: 'warning',
        confirmButtonText: 'SIM',
        showDenyButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return DevicesService.delete(device.id);
        },
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire({
              icon: 'success',
              title: 'Removido!',
              text: 'Dispositivo removido com sucesso!',
              timer: 2000,
              showConfirmButton: false,
            });
            this.fetch();
          }
        })
        .catch(() => {
          this.$swal.close();
        });
    },

    sync(device) {
      this.$swal({
        html: `<span>Deseja sincronizar '${device.name}'? <br /><br /> IMPORTANTE:<br /> As informações de faces e usuários serão removidas e sobrescritas</span>`,
        icon: 'warning',
        confirmButtonText: 'SIM',
        showDenyButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return DevicesService.sync(device.id);
        },
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire({
              icon: 'success',
              title: 'Sincronizado!',
              text: 'Dispositivo sincronizado com sucesso!',
              timer: 2000,
              showConfirmButton: false,
            });
            this.fetch();
          }
        })
        .catch(() => {
          this.$swal.close();
        });
    },

    viewAllUsers(device) {
      this.$refs.modalViewListUsers.$emit('viewListUsers', device.id);
    },
  },
};
</script>

<template>
  <div class="mt-10">
    <v-row class="pb-3">
      <v-col cols="12" sm="4">
        <h5 class="pt-4">Dispositivos</h5>
      </v-col>
      <v-col cols="12" sm="8" class="text-right">
        <v-btn color="primary" @click="create">Novo</v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 50] }"
      class="elevation-3 no-line-row"
    >
      <template v-slot:[`item.accessType`]="{ item }">
        {{ deviceAccessType[item.accessType] | empty }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon title="Editar" @click="edit(item)">
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
        <v-btn icon color="info" title="Ver usuários" @click="viewAllUsers(item)">
          <v-icon small> mdi-account-multiple </v-icon>
        </v-btn>
        <v-btn icon color="error" title="Remover" @click="remove(item)">
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
        <v-btn icon color="warning" title="Sincronizar dispositivo" @click="sync(item)">
          <v-icon small> mdi-sync </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <modal-register-device ref="modalRegister" @success="fetch" />
    <modal-list-users-device ref="modalViewListUsers" />
  </div>
</template>
